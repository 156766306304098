import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Dropdown } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import DeleteComponent from "./DeleteComponent";
import { vsmNotify } from "../../../../config/messages";
import ReactDOMServer from "react-dom/server";
import StructureTableContentPost from "../../RightSideComponent/Structure/component/StructureTableContentPost";
import ConfirmDraftSSSEdit from "../component/ConfirmDraftSSSEdit";
import { useTranslation } from "react-i18next";

const Footer = observer((props) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [copySelectionVisible, setCopySelectionVisible] = useState(false);
  const [summeryWidth, setSummeryWidth] = useState(0);
  const { t } = useTranslation();

  const { listingType } = props;

  //------------ Index Used for Active Schedule Edit Auto Save ----------  //
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();

  //------------ Used for Confirm Schedule Edit Auto Save ----------  //
  const [ConfirmScheduleVersion, setConfirmScheduleVersion] = useState();

  const {
    DEALDESKSTORE: {
      setDealdeskActiveTab,
      setEditActiveStructureData,
      setEditActiveScrutiniseData,
      editActiveStructureData,
      activeStructureData,
      editActiveScreenData,
      editActiveScrutiniseData,
      setStructureSchedulePostData,
      setEditActiveScreenData,
      setEditActiveScheduleData,
      setCopiedActiveScheduleData,
      removeDraftContentSSS,
    },
    SSSLEADLIBRARYSTORE: {
      setDealDeskRightSectionActiveTab,
      addUpdateNoteList,
      sss_list,
    },
    DEALDESKSTRUCTURELIBRARY: {
      savestructureform,
      SetStructure,
      SetScreen,
      SetScrunitise,
      setCopiedStructureData,
      setCopiedScreenData,
      setCopiedScrunitiseData,
    },
  } = useStore();

  //close delet drawer
  const closeDeleteDrawer = () => setDeleteConfirm(false);

  const copyMenuOverlay = (
    <div className="ant-popover-inner-content multipleTag">
      <div className="leadStatusOptins lockUserList createNotetoTicket">
        <ul>
          <li>
            <ul className="dealDeskCopySelection">
              <li
                onClick={() => {
                  if (activeStructureData) {
                    setEditActiveScrutiniseData(null);
                    setDealdeskActiveTab("7");
                    SetScrunitise(activeStructureData.description);
                    setCopiedScrunitiseData(activeStructureData.description);
                    setDealDeskRightSectionActiveTab("scrutinise");
                    if (savestructureform) {
                      savestructureform.setFieldsValue({
                        structure_title: activeStructureData.title,
                      });
                    }
                  }
                }}
              >
                {t("DealDesk.To_Scrutinise")}
              </li>
              <li
                onClick={() => {
                  if (activeStructureData) {
                    setEditActiveScreenData(null);
                    setDealdeskActiveTab("6");
                    SetScreen(activeStructureData.description);
                    setCopiedScreenData(activeStructureData.description);
                    setDealDeskRightSectionActiveTab("screen");
                    if (savestructureform) {
                      savestructureform.setFieldsValue({
                        structure_title: activeStructureData.title,
                      });
                    }
                  }
                }}
              >
                {t("DealDesk.To_Screen")}
              </li>
              <li
                onClick={() => {
                  if (activeStructureData) {
                    setEditActiveStructureData(null);
                    setDealdeskActiveTab("1");
                    setDealDeskRightSectionActiveTab("structure");
                    SetStructure(activeStructureData.description);
                    setCopiedStructureData(activeStructureData.description);

                    if (savestructureform) {
                      savestructureform.setFieldsValue({
                        structure_title: activeStructureData.title,
                      });
                    }
                  }
                }}
              >
                {t("DealDesk.To_Structure")}
              </li>
              <li
                onClick={() => {
                  if (activeStructureData) {
                    activeStructureData.description &&
                      activeStructureData.description.forEach((element) => {
                        if (element.children?.length > 0) {
                          element.children.forEach((element1) => {
                            if (element1.children?.length > 0) {
                              element1.children.forEach((element2) => {
                                element2.day = 0;
                              });
                            }
                            element1.day = 0;
                          });
                        }
                        element.day = 0;
                      });
                    setDealdeskActiveTab("2");
                    setEditActiveScheduleData(null);
                    setCopiedActiveScheduleData(activeStructureData);
                  }
                }}
              >
                {t("DealDesk.To_Schedule")}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const setEditScheduleData = (loadDraft = false) => {
    if (loadDraft) {
      let draftData = activeStructureData;
      let currentSelectedData = sss_list[currentSelectedIndex];
      if (sss_list[currentSelectedIndex]) {
        draftData.description = JSON.parse(
          currentSelectedData["draft_description"]
        );
        draftData.isEditAutoSaveData = true;
      }
      if (listingType === "screen") {
        setEditActiveScreenData(draftData);
      } else if (listingType === "structure") {
        setEditActiveStructureData(draftData);
      } else if (listingType === "scrutinise") {
        setEditActiveScrutiniseData(draftData);
      }
    } else {
      if (activeStructureData.draft_description) {
        removeDraftContentSSS(activeStructureData).then((data) => {
          addUpdateNoteList(data.data, true);

          if (listingType === "screen") {
            setEditActiveScreenData(data.data);
          } else if (listingType === "structure") {
            setEditActiveStructureData(data.data);
          } else if (listingType === "scrutinise") {
            setEditActiveScrutiniseData(data.data);
          }
        });
      } else {
        if (listingType === "screen") {
          setEditActiveScreenData(activeStructureData);
        } else if (listingType === "structure") {
          setEditActiveStructureData(activeStructureData);
        } else if (listingType === "scrutinise") {
          setEditActiveScrutiniseData(activeStructureData);
        }
      }
    }
  };
  const getSaveIDBasedOnSaveType = () => {
    if (listingType === "screen") {
      return 3;
    } else if (listingType === "structure") {
      return 1;
    } else if (listingType === "scrutinise") {
      return 4;
    }
  };

  const onEditSchedule = () => {
    let selectedIndex = sss_list.findIndex(
      (x) =>
        x.id === activeStructureData.id && x.type === getSaveIDBasedOnSaveType()
    );

    setCurrentSelectedIndex(selectedIndex);
    if (activeStructureData) {
      setCopiedStructureData(null);
      if (props?.listingType && listingType === "screen") {
        setDealdeskActiveTab("6");
        setDealDeskRightSectionActiveTab("screen");
      } else if (listingType === "structure") {
        setDealdeskActiveTab("1");
        setDealDeskRightSectionActiveTab("structure");
      } else if (listingType === "scrutinise") {
        setDealdeskActiveTab("7");
        setDealDeskRightSectionActiveTab("scrutinise");
      }

      if (selectedIndex !== -1) {
        let currentSelectedObj = sss_list[selectedIndex];
        if (
          currentSelectedObj.hasOwnProperty("draft_description") &&
          currentSelectedObj?.draft_description
        ) {
          setConfirmScheduleVersion(true);
        } else {
          setEditScheduleData();
        }
      }
    }
  };

  const getFormNameBasedOnListType = () => {
    if (listingType === "screen") {
      return "Screen";
    } else if (listingType === "structure") {
      return "Structure";
    } else if (listingType === "scrutinise") {
      return "Scrutinise";
    }
  };


  const updateWidth = () => {
    // setTimeout(() => {
    const parent = document.querySelector(".Dealdesk_MainTab");
    if (parent) {
      setSummeryWidth(parent.offsetWidth);
    }
    // }, 0); // Ensures DOM is ready
  };

  useLayoutEffect(() => {
    updateWidth(); // Set width on mount

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <>
      <div
        className="tabFooter displayTabs"
        style={{ width: `${summeryWidth}px` }}
      >
        <div className={`btnSectionBox`}>
          <Button
            shape="round"
            size="medium"
            disabled={
              (activeStructureData?.type === 3 && listingType === "screen") ||
              (activeStructureData?.type === 1 &&
                listingType === "structure") ||
              (activeStructureData?.type === 4 && listingType === "scrutinise")
                ? false
                : true
            }
            onClick={() => {
              onEditSchedule();
            }}
          >
            {t("Common_Data.Edit")}
          </Button>

          <Button
            shape="round"
            size="medium"
            disabled={
              (activeStructureData?.type === 3 && listingType === "screen") ||
              (activeStructureData?.type === 1 &&
                listingType === "structure") ||
              (activeStructureData?.type === 4 && listingType === "scrutinise")
                ? false
                : true
            }
            onClick={() => {
              if (activeStructureData) {
                if (
                  activeStructureData.id === editActiveStructureData?.id ||
                  activeStructureData.id === editActiveScreenData?.id ||
                  activeStructureData.id === editActiveScrutiniseData?.id
                ) {
                  vsmNotify.error({
                    message: `${getFormNameBasedOnListType()}${" "}${t(
                      "Common_Data.Already_In_Use"
                    )}!`,
                  });
                } else {
                  setDeleteConfirm(true);
                }
              }
            }}
          >
            {t("Common_Data.Delete")}
          </Button>

          <Dropdown
            overlay={copyMenuOverlay}
            overlayClassName="lockContentMenu"
            placement="topCenter"
            trigger="click"
            visible={copySelectionVisible}
            onVisibleChange={(x) => {
              setCopySelectionVisible(x);
            }}
            disabled={
              (activeStructureData?.type === 3 && listingType === "screen") ||
              (activeStructureData?.type === 1 &&
                listingType === "structure") ||
              (activeStructureData?.type === 4 && listingType === "scrutinise")
                ? false
                : true
            }
          >
            <Button shape="round" size="medium">
              {t("DealDesk.Copy")}
            </Button>
          </Dropdown>
        </div>
        <div>
          <Button
            shape="round"
            size="medium"
            disabled={
              (activeStructureData?.type === 3 && listingType === "screen") ||
              (activeStructureData?.type === 1 &&
                listingType === "structure") ||
              (activeStructureData?.type === 4 && listingType === "scrutinise")
                ? false
                : true
            }
            onClick={() => {
              if (activeStructureData) {
                setDealdeskActiveTab("5");

                setStructureSchedulePostData(
                  ReactDOMServer.renderToString(
                    <>
                      <StructureTableContentPost
                        Structure={activeStructureData.description}
                      />
                    </>
                  )
                );
              }
            }}
          >
            {t("DealDesk.Post")}
          </Button>
        </div>
      </div>
      <DeleteComponent
        listingType={listingType}
        visible={deleteConfirm}
        close={closeDeleteDrawer}
      />
      <ConfirmDraftSSSEdit
        visible={ConfirmScheduleVersion}
        sssName={getFormNameBasedOnListType()}
        setDraftNoteEditConfirmModal={setConfirmScheduleVersion}
        setEditNoteData={setEditScheduleData}
      />
    </>
  );
});

export default Footer;

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Dropdown, Spin, Modal, Tooltip } from "antd";
import useStore from "../../../../../store";
import FileManager, { handleAttach } from "../../../../FileManager";
import Attachments from "../../../../../component/Attachments";
import AutosaveNotifiy from "./AutosaveNotifiy";
import TagSelection from "./TagSelection";
import { useTranslation } from "react-i18next";

const EditorFooter = observer(
  ({
    selectedFiles,
    setSelectedFiles,
    selectedLockGroup,
    handleLockUserChange,
    handleLockGroupChange,
    selectedLockUsers,
    selectedTagGroup,
    selectedTagUser,
    setFormTagGroup,
    setFormTagUser,
    tagVisibleModal,
    tagVisible,
    clearFormValues,
    handleTagGroupChange,
    handleTagUserChange,
    ...props
  }) => {
    const {
      AUTH,
      DEALDESKNOTESOUTBOUND: {
        fetchLockListGroupUsers,
        autoSaveIDTempData,
        removeAutosaveNote,
      },
      DEALDESKSTORE: {
        deal_data,
        activeNoteEditDated,
        resetActiveNoteEditDated,
      },
      DEALDESKNOTESEQUENCE: {
        resetAutosaveEditNoteData,
        autoSavedNoteData,
        setAutoSavedNoteData,
      },
    } = useStore();
    const { t } = useTranslation();
    const {
      openExpandModal,
      openModal,
      close,
      lockVisibleModal,
      lockVisible,
      formEmailNotify,
      setFormEmailNotify,
      setTagVisibleModal,
      setTagVisible,
      setLockVisibleModal,
      isNoteTabID,
      disabled,
      setLockVisible,
      formWhatsappNotify,
      setFormWhatsappNotify,
      setAttachment,
      attachment,
      id,
      handleSubmit,
      autoSaveData,
      saveObj,
      insideModal
    } = props;

    const [selecting, setSelecting] = useState(false);
    const [clearAutosaveLoader, setClearAutosaveLoader] = useState(false);
    const fileManagerRef = React.createRef();

    const [lockUserList, setLockUserList] = useState(null);
    const [openFileManager, setOpenFileManager] = useState(false);
    const [disableFileBtn, setDisableFileBtn] = useState(true);

    const lockContent = lockUserList ? (
      <div className="ant-popover-inner-content">
        <div className="leadStatusOptins lockUserList">
          <ul>
            {lockUserList && lockUserList.userGroupList && (
              <li key="1">
                <span style={{ padding: "0 0 0 8px" }}>
                  {t("DealDesk.Users_Group")}
                </span>
                <ul>
                  {lockUserList.userGroupList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedLockGroup &&
                          selectedLockGroup.includes(item.id)
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          handleLockGroupChange(item);
                        }}
                      >
                        <Tooltip title={item.group_name}>
                          {item.group_name}
                        </Tooltip>
                      </li>
                    );
                  })}
                </ul>
              </li>
            )}
            {lockUserList && lockUserList.userList && (
              <li key="2">
                <span style={{ padding: "0 0 0 8px" }}>
                  {t("DealDesk.Users_List")}
                </span>
                <ul>
                  {lockUserList.userList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedLockUsers &&
                          selectedLockUsers.includes(item.id)
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          handleLockUserChange(item);
                        }}
                      >
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </li>
                    );
                  })}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    ) : (
      <div className="ant-popover-inner-content">
        <div className="leadStatusOptins lockUserList">
          <ul className="popover-menu cursorPointer spinInside">
            <Spin tip={`${t("Common_Data.Load")}...`} />
          </ul>
        </div>
      </div>
    );
    const openFileManagerModal = () => {
      setDisableFileBtn(true);
      setOpenFileManager(true);
    };
    const closeFileManagerModal = () => {
      setOpenFileManager(false);
      // setSelectedFiles(selectedFiles)
    };

    const autosaveClearHandle = () => {
      let dealid = deal_data.lead_information && deal_data.lead_information.id;
      let autosaveNoteID = null;
      if (autoSaveIDTempData) {
        autosaveNoteID = autoSaveIDTempData?.id;
      } else {
        autosaveNoteID = autoSavedNoteData?.id;
      }
      if (autosaveNoteID) {
        setClearAutosaveLoader(true);
        removeAutosaveNote(dealid, autosaveNoteID)
          .then((data) => {
            resetAutosaveEditNoteData();
            clearFormValues();
            if (close) {
              close();
            }
          })
          .catch((e) => {})
          .finally(() => {
            setClearAutosaveLoader(false);
          });
      }
    };

    return (
      <>
        <div
          className="tabFooter modalFooter"
          style={{ position: insideModal ? "static" : "" }}
        >
          <Attachments
            autoSaveData={autoSaveData}
            saveObj={saveObj}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isFromEditorFooter={true}
            openExpanModal={openModal}
          />

          <div className="btnSectionBox">
            <Dropdown
              // overlay={tagsContent}
              overlay={
                <TagSelection
                  lockUserList={lockUserList}
                  saveObj={saveObj}
                  autoSaveData={autoSaveData}
                  selectedTagGroup={selectedTagGroup}
                  selectedTagUser={selectedTagUser}
                  setFormTagGroup={setFormTagGroup}
                  handleTagGroupChange={handleTagGroupChange}
                  handleTagUserChange={handleTagUserChange}
                  setFormTagUser={setFormTagUser}
                  tagselectionPopupOpen={tagVisibleModal || tagVisible}
                />
              }
              overlayClassName="lockContentMenu"
              placement="topCenter"
              trigger="click"
              visible={openModal ? tagVisibleModal : tagVisible}
              onVisibleChange={() => {
                if (openModal) {
                  setTagVisibleModal(!tagVisibleModal);
                } else {
                  setTagVisible(!tagVisible);
                }
              }}
            >
              <Button
                size="small"
                className={
                  (selectedTagGroup && selectedTagGroup.length > 0) ||
                  (selectedTagUser && selectedTagUser.length > 0)
                    ? "darkBG"
                    : "lightBG"
                }
                title={`${t("DealDesk.Tag")}`}
              >
                <img
                  src={AUTH.GetThemedImage("Tags")}
                  alt=""
                  className="icon"
                  onMouseEnter={() => {
                    if (!lockUserList) {
                      fetchLockListGroupUsers().then((data) => {
                        setLockUserList(data);
                      });
                    }
                  }}
                />
              </Button>
            </Dropdown>
            <Dropdown
              overlay={lockContent}
              overlayClassName="lockContentMenu"
              placement="topCenter"
              trigger="click"
              visible={openModal ? lockVisibleModal : lockVisible}
              onVisibleChange={() => {
                if (openModal) {
                  setLockVisibleModal(!lockVisibleModal);
                } else {
                  setLockVisible(!lockVisible);
                }
              }}
            >
              <Button
                size="small"
                className={
                  (selectedLockGroup && selectedLockGroup.length > 0) ||
                  (selectedLockUsers && selectedLockUsers.length > 0)
                    ? "darkBG"
                    : "lightBG"
                }
                title={`${t("DealDesk.Lock_User")}`}
              >
                <img
                  src={AUTH.GetThemedImage("Lock_Note")}
                  alt=""
                  className="icon"
                  onMouseEnter={() => {
                    if (!lockUserList) {
                      fetchLockListGroupUsers().then((data) => {
                        setLockUserList(data);
                      });
                    }
                  }}
                />
              </Button>
            </Dropdown>

            <Button
              size="small"
              className={
                selectedFiles && selectedFiles.length > 0 ? "darkBG" : "lightBG"
              }
              onClick={() => {
                setAttachment(!attachment);
                openFileManagerModal();
              }}
              disabled={!deal_data.lead_information?.organisation_name?.id}
              title={`${t("DealDesk.Attachment")}`}
            >
              <img
                src={AUTH.GetThemedImage("Attach_File_icn")}
                alt=""
                className="icon"
              />
            </Button>

            {deal_data?.lead_information?.email?.length > 0 && (
              <Button
                size="small"
                className={formEmailNotify ? "darkBG" : "lightBG"}
                onClick={() => setFormEmailNotify(!formEmailNotify)}
                title={`${t("DealDesk.Email_Notification")}`}
              >
                <img
                  src={AUTH.GetThemedImage("Mail")}
                  alt=""
                  className="icon"
                />
              </Button>
            )}
            <Button
              size="small"
              className={formWhatsappNotify ? "darkBG" : "lightBG"}
              onClick={() => setFormWhatsappNotify(!formWhatsappNotify)}
              title={`${t("DealDesk.SMS_Notification")}`}
            >
              <img src={AUTH.GetThemedImage("SMS")} alt="" className="icon" />
            </Button>
            {openModal && openModal === true ? (
              <Button
                size="small"
                className="lightBG"
                onClick={() => {
                  close(props.data);
                }}
                title={`${t("DealDesk.Close")}`}
              >
                <img
                  src={AUTH.GetThemedImage("CloseExpand")}
                  alt={`${t("DealDesk.CloseExpand")}`}
                  className="icon"
                />
              </Button>
            ) : (
              <Button
                size="small"
                className="lightBG"
                onClick={() => {
                  openExpandModal(props.data);
                }}
                title={`${t("DealDesk.Expand")}`}
              >
                <img
                  src={AUTH.GetThemedImage("Expand")}
                  alt=""
                  className="icon"
                />
              </Button>
            )}
          </div>
          <div>
            <AutosaveNotifiy />

            {!activeNoteEditDated &&
              (autoSaveIDTempData ||
                (autoSavedNoteData &&
                  !autoSavedNoteData?.isEditAutoSaveData)) && (
                <Button
                  type="primary"
                  shape="round"
                  loading={clearAutosaveLoader}
                  onClick={() => autosaveClearHandle()}
                >
                  {t("Common_Data.Clear")}
                </Button>
              )}

            {activeNoteEditDated && (
              <Button
                type="primary"
                shape="round"
                //htmlType='submit'
                onClick={() => {
                  resetActiveNoteEditDated();
                  clearFormValues();
                  if (autoSaveIDTempData && !autoSavedNoteData) {
                    setAutoSavedNoteData(autoSaveIDTempData);
                  }
                  if (close) {
                    close();
                  }
                }}
                id={id}
              >
                {t("Common_Data.Cancel")}
              </Button>
            )}

            <Button
              type="primary"
              shape="round"
              //htmlType='submit'
              onClick={(data) => handleSubmit(0, 0, selectedFiles)}
              id={id}
              disabled={disabled}
            >
              {t("Common_Data.Save")}
            </Button>
            {isNoteTabID === "4" ? (
              <Button
                shape="round"
                onClick={(data) => handleSubmit(1, 0, selectedFiles)}
                id={id}
                disabled={disabled}
              >
                {t("Common_Data.Send")}
              </Button>
            ) : null}
          </div>
        </div>

        <Modal
          centered
          title={`${t("DealDesk.File_Manager")}`}
          visible={openFileManager}
          onCancel={closeFileManagerModal}
          onOk={closeFileManagerModal}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          width={"90%"}
          zIndex="1350"
          className="FileManager"
          destroyOnClose={true}
          footer={[
            <Button
              key="1"
              loading={selecting}
              shape="round"
              size="medium"
              disabled={disableFileBtn}
              onClick={() => {
                setSelecting(true);
                handleAttach(
                  fileManagerRef,
                  selectedFiles,
                  setSelectedFiles,
                  deal_data
                )
                  .then((data) => {
                    closeFileManagerModal();
                    if (saveObj) {
                      let newObj = saveObj();
                      if (data) {
                        newObj.selectedFiles = data;
                      }
                      autoSaveData(newObj);
                    }
                  })
                  .finally(() => {
                    setSelecting(false);
                  });
              }}
            >
              {t("Common_Data.Select")}
            </Button>,
            <Button
              key="2"
              htmlType="button"
              shape="round"
              size="medium"
              onClick={() => closeFileManagerModal()}
            >
              {t("Common_Data.Cancel")}
            </Button>,
          ]}
        >
          <FileManager
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            fileManagerRef={fileManagerRef}
            setDisableFileBtn={setDisableFileBtn}
          />
        </Modal>
      </>
    );
  }
);

export default EditorFooter;

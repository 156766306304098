import React, { useState, useMemo } from "react";
import { observer } from "mobx-react";
import { Form, Row, Col, Spin, Select, Tooltip } from "antd";
import InputComponent from "../../../component/InputComponent";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import useStore from "../../../store";
import { vsmLeadsList, vsmNotify } from "../../../config/messages";
import PhoneElement from "../elements/PhoneElement";
import EmailElement from "../elements/EmailElement";
import debounce from "lodash/debounce";

import StatusConfirmElements from "../elements/StatusConfirmElements";
import ConnectionComponent from "./ConnectionComponent";
import { useTranslation } from "react-i18next";
import copy from "copy-to-clipboard";

const { Option, OptGroup } = Select;

const FormComponent = observer(
  ({
    handleSubmit,
    form,
    onChange,
    // props,
    // visible,
    id,
    formid,
    openAddModal,
    openAddOrgModal,
    openEditPeopleDrawer,
    openEditOrgDrawer,
    setFailedReason,
    connectionList,
    primaryConnectionList,
    setPrimaryConnectionList,
    setConnectionList,
    setSoldStatus,
    editSoldStatus,
    AddNewLead,
  }) => {
    const {
      AUTH,
      PEOPLE,
      LEADSSTORE,
      ORGANISATION,
      LEADSTATUS,
      LEADSSTORE: {
        classification_role_list,
        dropdown_currency_list,
        dropdown_users_list,
        dropdown_leadstatus_list,
        getCurrencyList,
        getUsersList,
        getClassificationList,
        getLeadStatusList,
        getProductList,
        getSoldSourceList,
        // leadSourceArray,
      },
    } = useStore();
    const { t } = useTranslation();
    const [fetchClassification, setFetchClassification] = useState(true);
    const [fetchCurrencyList, setFetchCurrencyList] = useState(true);
    const [fetchUsersList, setFetchUsersList] = useState(true);
    const [fetchLeadstatusList, setFetchLeadstatusList] = useState(true);
    const [fetchProductList, setFetchProductList] = useState(true);
    const [searchPplKey, setSearchPplKey] = useState();
    const [searchOrgKey, setSearchOrgKey] = useState();
    const [showOrgEditBtn, setShowOrgEditBtn] = useState(false);
    const [showPeopleEditBtn, setShowPeopleEditBtn] = useState(false);
    const [fetchRecordType, setFetchRecordType] = useState(true);
    const [fetchSoldSourceList, setFetchSoldSourceList] = useState(true);

    const [openleadStatusOptins, setOpenleadStatusOptins] = useState(false);
    const [statusUpdateData, setStatusUpdateData] = useState(null);

    //-------------connectionVisible-----------------------//
    const [connectionVisible, setConnectionVisible] = useState(null);

    const openleadStatusModal = (new_id) => {
      let tempDataStatusUpdate = {
        new_id: new_id,
        id: LEADSSTORE.editValues ? LEADSSTORE.editValues.id : 0,
      };
      setStatusUpdateData(tempDataStatusUpdate);
      setOpenleadStatusOptins(true);
    };

    const closeleadStatusOptins = () => {
      setStatusUpdateData(null);
      setOpenleadStatusOptins(false);
    };

    const onFinish = (data) => {
      handleSubmit(data);
    };

    const handleSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getAllPeople({ search_for: val, mapping_type: 2 });
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE]);

    // search string for dropdown
    const handleOrgSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgKey(val);
        ORGANISATION.getAllROrg({ search_for: val, mapping_type: 1 });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION]);

    /** Call on Lead record type/SOLD Type change */
    const handleDealStatus = (e) => {
      form.resetFields(["lead_status_id"]);
      LEADSSTORE.getLeadStatusList(e).then(({ data }) => {
        setFetchLeadstatusList(false);
        LEADSSTORE.dropdown_leadstatus_list = data;
        form.setFieldsValue({
          lead_status_id: null,
        });
      });
    };

    //-----------------START: CONNECTION ADD-REMOVE FUNCTIONS-------------------------------//
    const openConnection = () => {
      setConnectionVisible(true);
    };

    const closeConnection = (is_saved = false) => {
      if (!is_saved) {
        setConnectionList(LEADSSTORE.oldConnectionList);
      }
      setConnectionVisible(false);
      onChange();
      ORGANISATION.dropdown_ROrg_connection_list = null;
      PEOPLE.dropdown_people_connection_list = null;
    };

    const copyIcon = (ticket_num) => {
      return (
        <img
          className="ml-5"
          style={{ width: "10px" }}
          src={AUTH.GetThemedImage("Copy_icn")}
          alt={`${t("DealDesk.Copy")}`}
          onClick={(event) => {
            copy(ticket_num);
            vsmNotify.success({
              message: `${t("DealDesk.Copied")}!`,
            });
          }}
        />
      );
    };
    //-----------------END: CONNECTION ADD-REMOVE FUNCTIONS-------------------------------//
    return (
      <>
        <Form
          form={form}
          //labelCol={{ span: 24 }}
          layout="vertical"
          id={formid}
          onFinish={onFinish}
          className="innerForm"
          initialValues={{ is_quality_lead: null }}
        >
          <Row gutter={15}>
            <Col span={10}>
              <InputComponent
                name="opportunity_name"
                label={`${t("Common_Data.SOLD_Name")}`}
                placeholder={`${t("Common_Data.SOLD_Name")}`}
                type="text"
                tooltip={`${t("LeadListing.Three_Words")}.`}
                onChange={onChange}
                maxLength={100}
              />
            </Col>
            <Col span={6}>
              <InputComponent
                name="classification_id"
                placeholder={`${t(
                  "Common_Data.Select_Credebt_Classification"
                )}`}
                label={
                  <span
                    className="converbillLink"
                    onClick={() => {
                      window.open(
                        "https://www.convertibill.com/credebtclassifications-popup.html",
                        "_blank",
                        "location=yes,height=570,width=650,scrollbars=yes,status=yes"
                      );
                    }}
                  >
                    {t("Common_Data.Credebt_Classification")}
                  </span>
                }
                required
                type="select"
                // mode='multiple'
                allowClear
                showArrow
                showSearch
                onChange={onChange}
                options={{
                  values:
                    classification_role_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.classifications && [
                        LEADSSTORE.editValues.classifications,
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.classifications && [
                        LEADSSTORE.cloneValues.classifications,
                      ]),
                  value_key: "id",
                  text_key: "name",
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
                onFocus={() =>
                  fetchClassification &&
                  getClassificationList().then(() =>
                    setFetchClassification(false)
                  )
                }
                notFoundContent={
                  fetchClassification ? (
                    <Spin size="small" />
                  ) : (
                    `${t("Common_Data.No_Record_Found")}.`
                  )
                }
                tooltip={`${t("LeadListing.Classification_Group_Tooltip")}.`}
                rules={vsmLeadsList.validation.classification_id}
              />
            </Col>
            <Col span={4}>
              <InputComponent
                name="lead_record_type"
                label={`${t("Block_Edit_Assign.SOLD_Type")}`}
                placeholder={`${t("LeadStatus.SOLD_Type_Placeholder")}`}
                required
                type="select"
                tooltip={`${t("LeadListing.Sold_Record_Tooltip")}.`}
                onChange={(e) => {
                  handleDealStatus(e);
                  onChange();
                }}
                allowClear
                showArrow
                showSearch
                options={{
                  values: LEADSTATUS.recordTypeList,
                  value_key: "record_type_name",
                  text_key: "record_type_name",
                }}
                initialValue={`${t("LeadListing.Originator")}`}
                rules={vsmLeadsList.validation.lead_record_type}
                onFocus={() =>
                  fetchRecordType &&
                  LEADSTATUS.getRecordType().then(() =>
                    setFetchRecordType(false)
                  )
                }
                notFoundContent={
                  fetchRecordType ? (
                    <Spin size="small" />
                  ) : (
                    `${t("Common_Data.No_Record_Found")}.`
                  )
                }
              />
            </Col>
            <Col span={4}>
              <>
                <Form.Item
                  label={`${t("Common_Data.Sold")} ${t("Common_Data.Status")}`}
                  required
                  tooltip={`${t("LeadListing.Marketing_Sales_Tooltip")}`}
                >
                  <Form.Item
                    name="lead_status_id"
                    noStyle
                    rules={vsmLeadsList.validation.lead_status_id}
                    initialValue={7}
                  >
                    <Select
                      className="w100"
                      onChange={() => {
                        if (
                          form.getFieldValue("lead_status_id") ===
                            AUTH.SOLD_STATUS_FAILED ||
                          form.getFieldValue("lead_status_id") ===
                            AUTH.SOLD_STATUS_REJECTED ||
                          form.getFieldValue("lead_status_id") ===
                            AUTH.SOLD_STATUS_FUTURE
                        ) {
                          openleadStatusModal(
                            form.getFieldValue("lead_status_id")
                          );
                        } else {
                          setFailedReason(null);
                        }
                        onChange();
                      }}
                      allowClear
                      showArrow
                      showSearch
                      placeholder={`${t("Common_Data.Select")} ${t(
                        "Common_Data.Sold"
                      )} ${t("Common_Data.Status")}`}
                      optionFilterProp="children"
                      onFocus={() =>
                        fetchLeadstatusList &&
                        getLeadStatusList(
                          form.getFieldValue("lead_record_type")
                        ).then(() => setFetchLeadstatusList(false))
                      }
                      notFoundContent={
                        fetchLeadstatusList ? (
                          <Spin size="small" />
                        ) : (
                          `${t("Common_Data.No_Record_Found")}.`
                        )
                      }
                    >
                      {dropdown_leadstatus_list &&
                      dropdown_leadstatus_list.length
                        ? dropdown_leadstatus_list.map((parent_status) => (
                            <OptGroup
                              key={parent_status.id}
                              label={parent_status.status_name}
                            >
                              {parent_status.child_leads
                                .filter((item) => item.status_name !== "Unused")
                                .map((child_status) => (
                                  <Option
                                    value={child_status.id}
                                    key={child_status.id}
                                  >
                                    {child_status.status_name}
                                  </Option>
                                ))}
                            </OptGroup>
                          ))
                        : LEADSSTORE.editValues &&
                          LEADSSTORE.editValues.lead_status_id && (
                            <Option
                              value={LEADSSTORE.editValues.lead_status_id.id}
                            >
                              {LEADSSTORE.editValues.lead_status_id.status_name}
                            </Option>
                          )}
                    </Select>
                  </Form.Item>
                </Form.Item>
              </>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={8}>
              <InputComponent
                label={`${t("Contracts.Organisation_Name")}`}
                type="labelOnly"
                required
                tooltip={`${t(
                  "OrganisationManagement.Registered_Name_Tooltip"
                )}`}
              />
              <div className="suffixIcon">
                <InputComponent
                  name="organisation_name"
                  placeholder={`${t("Contracts.Organisation_Name_Select")}`}
                  required
                  allowClear
                  type="select"
                  onChange={() => {
                    if (form.getFieldValue("organisation_name")) {
                      let selectedName =
                        ORGANISATION.dropdown_ROrg_list &&
                        ORGANISATION.dropdown_ROrg_list.filter(
                          (x) =>
                            x.id === form.getFieldValue("organisation_name")
                        );
                      if (selectedName?.length > 0) {
                        selectedName = selectedName[0]["entity_name"];
                      } else {
                        selectedName = "";
                      }
                      setShowOrgEditBtn(true);
                      let newOrg = {
                        id: form.getFieldValue("organisation_name"),
                        type: 1,
                        connection_role_name: [],
                        name: selectedName,
                      };
                      let currentSelected = primaryConnectionList;
                      if (currentSelected?.length > 0) {
                        let index = currentSelected.findIndex(
                          (x) => x.type === 1
                        );
                        if (index !== -1) {
                          currentSelected.splice(index, 1);
                        }
                      }
                      setPrimaryConnectionList([...currentSelected, newOrg]);
                    } else {
                      setShowOrgEditBtn(false);
                    }
                    form.setFieldsValue({
                      email: [{ contact_for: `${t("LeadListing.Primary")}` }],
                      phone: [{ contact_for: `${t("LeadListing.Primary")}` }],
                    });
                    onChange();
                  }}
                  onSearch={handleOrgSearch}
                  notFoundContent={
                    ORGANISATION.fetching ? (
                      <Spin size="small" />
                    ) : searchOrgKey ? (
                      `${t("Common_Data.No_Record_Found")}.`
                    ) : null
                  }
                  options={{
                    values:
                      ORGANISATION.dropdown_ROrg_list ||
                      (LEADSSTORE.editValues &&
                        LEADSSTORE.editValues.sold_organisation && [
                          {
                            id: LEADSSTORE.editValues.sold_organisation.id,
                            entity_name:
                              LEADSSTORE.editValues.sold_organisation
                                .organisation_name,
                          },
                        ]) ||
                      (LEADSSTORE.cloneValues &&
                        LEADSSTORE.cloneValues.sold_organisation && [
                          {
                            id: LEADSSTORE.cloneValues.sold_organisation.id,
                            entity_name:
                              LEADSSTORE.cloneValues.sold_organisation
                                .organisation_name,
                          },
                        ]),

                    value_key: "id",
                    text_key: "entity_name",
                  }}
                  rules={vsmLeadsList.validation.organisation_name}
                />

                {AUTH.checkPermission(16, "add") ? (
                  <Tooltip
                    title={`${t("LeadListing.Add_Multiple_Organisation")}.`}
                  >
                    <PlusCircleOutlined
                      className="extraFromIcon"
                      onClick={() => openAddOrgModal()}
                    />
                  </Tooltip>
                ) : null}

                {AUTH.checkPermission(16, "edit") ? (
                  form.getFieldValue("organisation_name") ||
                  LEADSSTORE.editValues?.sold_organisation ||
                  showOrgEditBtn ? (
                    <EditOutlined
                      className="extraFromIcon"
                      onClick={() => openEditOrgDrawer()}
                    />
                  ) : null
                ) : null}
              </div>
            </Col>
            <Col span={4}>
              <InputComponent
                label={`${t("Common_Data.Knowledge")}`}
                type="labelOnly"
                tooltip={`${t("LeadListing.Select_Screen_Questions")} {${t(
                  "LeadListing.Sold_Initial_Chat_Box"
                )}}`}
              />
              <div className="suffixIcon">
                <InputComponent
                  name="knowledge_id"
                  placeholder={`${t("Common_Data.Select")} ${t(
                    "Common_Data.Knowledge"
                  )}`}
                  allowClear
                  type="select"
                  options={{
                    values: [],
                    value_key: "id",
                    text_key: "name",
                  }}
                  disabled={true}
                />
              </div>
            </Col>
            <Col span={4}>
              <InputComponent
                name="product_name"
                label={`${t("Common_Data.Sold")} ${t(
                  "LeadListing.Product"
                )}/${t("LeadListing.Service")}`}
                placeholder={`${t("Common_Data.Sold")} ${t(
                  "LeadListing.Product"
                )}/${t("LeadListing.Service")}`}
                type="select"
                onChange={onChange}
                allowClear
                showArrow
                showSearch
                tooltip={`${t("LeadListing.Choose_To_Product_Created")}.`}
                rules={vsmLeadsList.validation.product_name}
                onFocus={() =>
                  fetchProductList &&
                  getProductList().then(() => setFetchProductList(false))
                }
                notFoundContent={
                  fetchProductList ? (
                    <Spin size="small" />
                  ) : (
                    `${t("Common_Data.No_Record_Found")}.`
                  )
                }
                options={{
                  values:
                    LEADSSTORE.product_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.product_name && [
                        { product_name: LEADSSTORE.editValues.product_name },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.product_name && [
                        { product_name: LEADSSTORE.cloneValues.product_name },
                      ]),
                  value_key: "product_name",
                  text_key: "product_name",
                }}
              />
            </Col>
            <Col span={4}>
              <InputComponent
                name="lead_currency_id"
                label={`${t("LeadListing.Currency")}`}
                required
                allowClear
                showArrow
                showSearch
                placeholder={`${t("LeadListing.Currency")}`}
                type="select"
                tooltip={`${t("LeadListing.Currency_Tooltip")}`}
                onChange={onChange}
                onFocus={() =>
                  fetchCurrencyList &&
                  getCurrencyList().then(() => setFetchCurrencyList(false))
                }
                notFoundContent={
                  fetchCurrencyList ? (
                    <Spin size="small" />
                  ) : (
                    `${t("Common_Data.No_Record_Found")}.`
                  )
                }
                options={{
                  values:
                    dropdown_currency_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.lead_currency_id && [
                        {
                          id: LEADSSTORE.editValues.lead_currency_id.id,
                          currency_code:
                            LEADSSTORE.editValues.lead_currency_id
                              .currency_code,
                        },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.lead_currency_id && [
                        {
                          id: LEADSSTORE.cloneValues.lead_currency_id.id,
                          currency_code:
                            LEADSSTORE.cloneValues.lead_currency_id
                              .currency_code,
                        },
                      ]),

                  value_key: "id",
                  text_key: "currency_code",
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
                rules={vsmLeadsList.validation.lead_currency_id}
              />
            </Col>
            <Col span={4}>
              <InputComponent
                name="finance_value"
                label={`${t("LeadListing.SOLD_Value")}`}
                placeholder={`${t("LeadListing.SOLD_Value")}`}
                // type='number'
                min={1}
                tooltip={`${t("LeadListing.Enter_Precise_Value")}.`}
                onChange={onChange}
                rules={vsmLeadsList.validation.finace_value}
              />
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={8}>
              <InputComponent
                label={`${t("LeadListing.Person_Name")}`}
                type="labelOnly"
                required
                tooltip={`${t("LeadListing.Person_Tooltip")}`}
              />
              <div className="suffixIcon">
                <InputComponent
                  name="full_name"
                  placeholder={`${t("Common_Data.Select")} ${t(
                    "LeadListing.Person_Name"
                  )}`}
                  required
                  type="select"
                  showSearch={true}
                  onSearch={handleSearch}
                  allowClear
                  onChange={() => {
                    if (form.getFieldValue("full_name")) {
                      let selectedName =
                        PEOPLE.drowpdown_people_list &&
                        PEOPLE.drowpdown_people_list.filter(
                          (x) => x.id === form.getFieldValue("full_name")
                        );
                      if (selectedName?.length > 0) {
                        selectedName = selectedName[0]["entity_name"];
                      } else {
                        selectedName = "";
                      }
                      let newOrg = {
                        id: form.getFieldValue("full_name"),
                        type: 2,
                        connection_role_name: [],
                        name: selectedName,
                      };
                      let currentSelected = primaryConnectionList;
                      if (currentSelected?.length > 0) {
                        let index = currentSelected.findIndex(
                          (x) => x.type === 2
                        );
                        if (index !== -1) {
                          currentSelected.splice(index, 1);
                        }
                      }
                      setPrimaryConnectionList([...currentSelected, newOrg]);
                      setShowPeopleEditBtn(true);
                    } else {
                      setShowPeopleEditBtn(false);
                    }
                    onChange();
                  }}
                  notFoundContent={
                    PEOPLE.fetching ? (
                      <Spin size="small" />
                    ) : searchPplKey ? (
                      `${t("Common_Data.No_Record_Found")}.`
                    ) : null
                  }
                  options={{
                    values:
                      PEOPLE.drowpdown_people_list ||
                      (LEADSSTORE.editValues &&
                        LEADSSTORE.editValues.people && [
                          {
                            id: LEADSSTORE.editValues.people.id,
                            entity_name:
                              (LEADSSTORE.editValues.people.first_name
                                ? LEADSSTORE.editValues.people.first_name
                                : "") +
                              " " +
                              (LEADSSTORE.editValues.people.last_name
                                ? LEADSSTORE.editValues.people.last_name
                                : ""),
                          },
                        ]) ||
                      (LEADSSTORE.cloneValues &&
                        LEADSSTORE.cloneValues.people && [
                          {
                            id: LEADSSTORE.cloneValues.people.id,
                            entity_name:
                              (LEADSSTORE.cloneValues.people.first_name
                                ? LEADSSTORE.cloneValues.people.first_name
                                : "") +
                              " " +
                              (LEADSSTORE.cloneValues.people.last_name
                                ? LEADSSTORE.cloneValues.people.last_name
                                : ""),
                          },
                        ]),
                    value_key: "id",
                    text_key: "entity_name",
                    // rejected_keys: LEADSSTORE.editValues && [
                    //   LEADSSTORE.editValues.id,
                    // ],
                  }}
                  rules={vsmLeadsList.validation.full_name}
                />
                {AUTH.checkPermission(18, "add") && !id ? (
                  <Tooltip title={`${t("LeadListing.Add_Multiple_People")}`}>
                    <PlusCircleOutlined
                      className="extraFromIcon"
                      onClick={() => openAddModal()}
                    />
                  </Tooltip>
                ) : null}
                {AUTH.checkPermission(18, "edit") && !id ? (
                  form.getFieldValue("full_name") ||
                  LEADSSTORE.editValues?.people ||
                  showPeopleEditBtn ? (
                    <EditOutlined
                      className="extraFromIcon"
                      onClick={() => openEditPeopleDrawer()}
                    />
                  ) : null
                ) : null}
              </div>
            </Col>
            <Col span={4}>
              <InputComponent
                name="timeline_date"
                label={`${t("LeadListing.Timeline")} ${t("Common_Data.Date")}`}
                type="date"
                placeholder={`${t("Common_Data.Select")} ${t(
                  "Common_Data.Date"
                )}`}
                tooltip={`${t("LeadListing.Internal_Policy_Tooltip")}`}
                onChange={onChange}
                disabled={true}
              />
            </Col>
            <Col span={4}>
              <InputComponent
                name="lead_source"
                label={`${t("Common_Data.Sold")} ${t("Common_Data.Source")}`}
                placeholder={`${t("Common_Data.Select")} ${t(
                  "Common_Data.Source"
                )}`}
                type="select"
                onChange={onChange}
                allowClear
                showArrow
                showSearch
                tooltip={`${t("Reports.Sold_Source_Tooltip")}.`}
                onFocus={() =>
                  fetchSoldSourceList &&
                  getSoldSourceList().then(() => setFetchSoldSourceList(false))
                }
                notFoundContent={
                  fetchSoldSourceList ? (
                    <Spin size="small" />
                  ) : (
                    "No Record Found."
                  )
                }
                options={{
                  values: LEADSSTORE.dropdown_sold_souce_list,
                  value_key: "sold_source_name",
                  text_key: "sold_source_name",
                }}
              />
            </Col>
            <Col span={4}>
              <InputComponent
                name="lead_source_details"
                label={`${t("Common_Data.Sold")} ${t("Common_Data.Source")} ${t(
                  "LeadListing.Details"
                )}`}
                placeholder={`${t("Common_Data.Sold")} ${t(
                  "Common_Data.Source"
                )} ${t("LeadListing.Details")}`}
                type="input"
                onChange={onChange}
                tooltip={`${t("LeadListing.Lead_Source_Details_Tooltip")}.`}
              />
            </Col>

            <Col span={4}>
              <InputComponent
                name="turn_over"
                label={`${t("LeadListing.Turn_Over")}`}
                placeholder={`${t("LeadListing.Turn_Over")}`}
                // type='number'
                min={1}
                tooltip={`${t("LeadListing.Turn_Over_Tooltip")}.`}
                onChange={onChange}
                rules={vsmLeadsList.validation.turn_over}
              />
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={8}>
              <InputComponent
                label={`${t("LeadListing.Related")} ${t(
                  "LeadListing.Person"
                )}/${t("Common_Data.Organisation")} ${t(
                  "Common_Data.Name"
                )}(s)`}
                type="labelOnly"
                tooltip={`${t("LeadListing.Related_Person_Tooltip")}`}
              />
              <div className="suffixIcon">
                <InputComponent
                  name="connections"
                  placeholder={`${t("Common_Data.Select")} ${t(
                    "LeadListing.Related"
                  )} ${t("LeadListing.Person")}/${t(
                    "Common_Data.Organisation"
                  )}`}
                  required
                  type="select"
                  mode="multiple"
                  allowClear
                  options={{
                    values: connectionList,
                    value_key: "id",
                    text_key: "name",
                  }}
                  disabled={true}
                />
                <Tooltip
                  title={`${t("LeadListing.Add_Multiple_People")}. ${t(
                    "LeadListing.Add_Multiple_Organisation"
                  )}.`}
                >
                  <PlusCircleOutlined
                    className="extraFromIcon"
                    onClick={() => {
                      //if (1 === 2) {
                      openConnection(true);
                      //}
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
            <Col span={4}>
              <InputComponent
                name="deadline_date"
                label={`${t("LeadKanbanView.DeadLine_Date")}`}
                type="date"
                placeholder={`${t("Common_Data.Select")} ${t(
                  "Common_Data.Date"
                )}`}
                tooltip={`${t("LeadListing.Deadline_Date_Tooltip")}`}
                onChange={onChange}
              />
            </Col>
            <Col span={6}>
              <InputComponent
                name="lead_owner_id"
                label={`${t("Common_Data.Sold")} ${t("Common_Data.Owner")}`}
                type="select"
                allowClear
                showArrow
                showSearch
                placeholder={`${t("Common_Data.Select")} ${t(
                  "Common_Data.Owner"
                )}`}
                tooltip={`${t("LeadListing.Sold_Owner_Tooltip")}.`}
                onChange={onChange}
                onFocus={() =>
                  fetchUsersList &&
                  getUsersList().then(() => setFetchUsersList(false))
                }
                notFoundContent={
                  fetchUsersList ? (
                    <Spin size="small" />
                  ) : (
                    `${t("Common_Data.No_Record_Found")}.`
                  )
                }
                options={{
                  values:
                    dropdown_users_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.lead_owner_name && [
                        {
                          id: LEADSSTORE.editValues.lead_owner_name.id,
                          name:
                            (LEADSSTORE.editValues.lead_owner_name.first_name
                              ? LEADSSTORE.editValues.lead_owner_name.first_name
                              : "") +
                            " " +
                            (LEADSSTORE.editValues.lead_owner_name.last_name
                              ? LEADSSTORE.editValues.lead_owner_name.last_name
                              : ""),
                        },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.lead_owner_name && [
                        {
                          id: LEADSSTORE.cloneValues.lead_owner_name.id,
                          name:
                            (LEADSSTORE.cloneValues.lead_owner_name.first_name
                              ? LEADSSTORE.cloneValues.lead_owner_name
                                  .first_name
                              : "") +
                            " " +
                            (LEADSSTORE.cloneValues.lead_owner_name.last_name
                              ? LEADSSTORE.cloneValues.lead_owner_name.last_name
                              : ""),
                        },
                      ]),

                  value_key: "id",
                  text_key: "name",
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
              />
            </Col>
            <Col span={6}>
              <InputComponent
                name="lead_holder_id"
                label={`${t("Common_Data.Sold")} ${t("Common_Data.Holder")}`}
                type="select"
                allowClear
                showArrow
                showSearch
                placeholder={`${t("Common_Data.Select")} ${t(
                  "Common_Data.Holder"
                )}`}
                tooltip={`${t("LeadListing.Deal_Holder_Tooltip")}.`}
                onChange={onChange}
                onFocus={() =>
                  fetchUsersList &&
                  getUsersList().then(() => setFetchUsersList(false))
                }
                notFoundContent={
                  fetchUsersList ? (
                    <Spin size="small" />
                  ) : (
                    `${t("Common_Data.No_Record_Found")}.`
                  )
                }
                options={{
                  values:
                    dropdown_users_list ||
                    (LEADSSTORE.editValues &&
                      LEADSSTORE.editValues.lead_holder_name && [
                        {
                          id: LEADSSTORE.editValues.lead_holder_name.id,
                          name:
                            (LEADSSTORE.editValues.lead_holder_name.first_name
                              ? LEADSSTORE.editValues.lead_holder_name
                                  .first_name
                              : "") +
                            " " +
                            (LEADSSTORE.editValues.lead_holder_name.last_name
                              ? LEADSSTORE.editValues.lead_holder_name.last_name
                              : ""),
                        },
                      ]) ||
                    (LEADSSTORE.cloneValues &&
                      LEADSSTORE.cloneValues.lead_holder_name && [
                        {
                          id: LEADSSTORE.cloneValues.lead_holder_name.id,
                          name:
                            (LEADSSTORE.cloneValues.lead_holder_name.first_name
                              ? LEADSSTORE.cloneValues.lead_holder_name
                                  .first_name
                              : "") +
                            " " +
                            (LEADSSTORE.cloneValues.lead_holder_name.last_name
                              ? LEADSSTORE.cloneValues.lead_holder_name
                                  .last_name
                              : ""),
                        },
                      ]),

                  value_key: "id",
                  text_key: "name",
                  // rejected_keys: LEADSSTORE.editValues && [
                  //   LEADSSTORE.editValues.id,
                  // ],
                }}
              />
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={12}>
              <InputComponent
                label={`${t("Common_Data.Telephone")} (${t(
                  "LeadListing.To_Add_Extension"
                )} ${t("LeadListing.Ext")} ,e.g : +xxx xxx xxx-xxxxxx ${t(
                  "LeadListing.Ext"
                )} xxxx)`}
                type="labelOnly"
                //required
              />
              <PhoneElement onChange={onChange} form={form} />
            </Col>
            <Col span={12}>
              <InputComponent
                label={`${t("Common_Data.Email_ID")}`}
                type="labelOnly"
                tooltip={`${t("LeadListing.Email_Type")}(${t(
                  "LeadListing.EG"
                )}.: ${t("LeadListing.Primary_Home_Work")}).`}
                //required
              />
              <EmailElement onChange={onChange} form={form} />
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={12}>
              <InputComponent
                name="initial_chat"
                label={`${t("Common_Data.Sold")} ${t(
                  "LeadListing.Initial"
                )} ${t("LeadListing.Chat")}`}
                placeholder={`${t("Common_Data.Sold")} ${t(
                  "LeadListing.Initial"
                )} ${t("LeadListing.Chat")}`}
                type="textarea"
                tooltip={`${t("LeadListing.Sold_Initial_Chat_Tooltip")}`}
                onChange={onChange}
                maxLength={100}
                rows={10}
                disabled={true}
              />
            </Col>

            <Col span={12}>
              <Row gutter={15}>
                <Col span={12} className="address_list">
                  <div className="addMore">
                    <PlusCircleOutlined className="mr-5 cursorPointer" />
                  </div>
                  <InputComponent
                    label={`${t("LeadListing.Relationships")}`}
                    tooltip={`${t("LeadListing.Select_Type_Relationship")} (${t(
                      "LeadListing.Created_Settings"
                    )})`}
                    type="labelOnly"
                  />
                  <Form.Item name={"Relationships"}>
                    <Select
                      label="test"
                      placeholder={`${t("LeadListing.Relationships")} ${t(
                        "Common_Data.Type"
                      )}`}
                      className="selfUpdatingOptions"
                      disabled={true}
                    >
                      <Select.Option value="Relationships Type 1">
                        {t("LeadListing.Relationships")} {t("Common_Data.Type")}{" "}
                        1
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={`${t("LeadListing.Related")} ${t(
                      "LeadListing.Items"
                    )}`}
                    tooltip={`${t("LeadListing.View_Select")} (${t(
                      "LeadListing.View_Select_Tooltip"
                    )})`}
                    name={"related_items"}
                  >
                    <Select
                      placeholder={`${t("LeadListing.Related")} ${t(
                        "LeadListing.Items"
                      )}`}
                      className="selfUpdatingOptions"
                      disabled={true}
                    >
                      <Select.Option value="Related Items 1">
                        {t("LeadListing.Related")} {t("LeadListing.Items")} 1
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* Tooltip on Input Only */}
                    <Tooltip
                      title={form.getFieldValue("gclid")}
                      placement="bottom"
                      overlayStyle={{ padding: "0px" }}
                    >
                      <div style={{ flex: 1 }}>
                        <InputComponent
                          label={`${t("LeadListing.GCLID")}`}
                          placeholder={`${t("LeadListing.GCLID")}`}
                          name={"gclid"}
                          disabled={true}
                        />
                      </div>
                    </Tooltip>

                    <span style={{ marginLeft: "8px", cursor: "pointer" }}>
                      {copyIcon(form.getFieldValue("gclid"))}
                    </span>
                  </div>
                </Col>
                {/* <Form initialValues={{ is_quality_lead: "" }}> */}
                <Col span={12} className="address_list">
                  <InputComponent
                    label={`${t("LeadListing.Quality_Lead")}`}
                    tooltip={`${t("LeadListing.Select_Type_Relationship")} (${t(
                      "LeadListing.Created_Settings"
                    )})`}
                    type="labelOnly"
                  />
                  <Form.Item name="is_quality_lead">
                    <Select
                      label="test"
                      placeholder={`${t("LeadListing.Quality_Lead")} ${t(
                        "Common_Data.Type"
                      )}`}
                      className="selfUpdatingOptions"
                      allowClear
                      onChange={onChange}
                    >
                      <Select.Option value={true}>
                        {t("LeadListing.Quality_Lead")}
                      </Select.Option>
                      <Select.Option value={false}>
                        {t("LeadListing.Not_Quality_Lead")}
                      </Select.Option>
                      <Select.Option value={null}>
                        {t("LeadListing.Not_Selected")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* </Form> */}
              </Row>
            </Col>
          </Row>
        </Form>
        <StatusConfirmElements
          visible={openleadStatusOptins}
          close={closeleadStatusOptins}
          statusUpdateData={statusUpdateData}
          isFromLeadForm={true}
          setFailedReason={setFailedReason}
          setSoldStatus={setSoldStatus}
          editSoldStatus={editSoldStatus}
          AddNewLead={AddNewLead}
        />
        <ConnectionComponent
          visible={connectionVisible}
          close={closeConnection}
          connectionList={connectionList}
          setPrimaryConnectionList={setPrimaryConnectionList}
          parentFormSelected={primaryConnectionList}
          primaryConnectionList={primaryConnectionList}
          setConnectionList={setConnectionList}
          mainform={form}
        />
      </>
    );
  }
);

export default FormComponent;

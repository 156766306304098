import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Form, Modal, AutoComplete, Radio, Button, Space } from "antd";
import useStore from "../../../store";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SearchModal = observer((props) => {
  const {
    AUTH,
    TagsStore,
    TicketsStore,
    PEOPLE,
    ORGANISATION,
    LEADDEALS,
    // LEADOPPORTUNITY,
    LEADACTIVE,
    // LEADSSTORE,
    REMINDERSSTORE,
    SEARCHSTORE: { plainOptions },
  } = useStore();
  const { visible } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const [isDisabled, setDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const searchRef = useRef(null);
  // const [radioValue, setRadioValue] = useState("");
  const { t } = useTranslation();
  const close = () => {
    setDisabled(true);
    setInputValue("");
    // setRadioValue("");
    form.resetFields();
    props.close();
  };

  const handleSubmit = async (data) => {
    if (!data.type) {
      data.type = "all";
    }
    var payload = {
      search: true,
      search_for: data.search,
      search_in: data.type,
    };

    if (data.type === "Tags" || data.type === "all") {
      TagsStore.search_payload = payload;
      if (TagsStore.agGrid) {
        TagsStore.setupGrid(TagsStore.agGrid);
      }
    }
    if (data.type === "Tickets" || data.type === "all") {
      TicketsStore.search_payload = payload;
      if (TicketsStore.agGrid) {
        TicketsStore.setupGrid(TicketsStore.agGrid);
      }
    }
    if (data.type === "People") {
      PEOPLE.search_payload = payload;
      if (PEOPLE.agGrid) {
        PEOPLE.setupGrid(PEOPLE.agGrid);
      }
    }
    if (data.type === "Organisations" || data.type === "all") {
      ORGANISATION.search_payload = payload;
      if (ORGANISATION.agGrid) {
        ORGANISATION.setupGrid(ORGANISATION.agGrid);
      }
    }
    if (data.type === "Deals" || data.type === "all") {
      LEADDEALS.search_payload = payload;
      if (LEADDEALS.agGrid) {
        LEADDEALS.setupGrid(LEADDEALS.agGrid);
      }
    }
    // if (data.type === 'Opportunities' || data.type === 'all') {
    //   LEADOPPORTUNITY.search_payload = payload;
    //   if (LEADOPPORTUNITY.agGrid) {
    //     LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
    //   }
    // }
    // if (data.type === 'UnusedLeads' || data.type === 'all') {
    //   LEADSSTORE.search_payload = payload;
    //   if (LEADSSTORE.agGrid) {
    //     LEADSSTORE.setupGridSearch(LEADSSTORE.agGrid);
    //   }
    // }
    if (data.type === "Leads" || data.type === "all") {
      LEADACTIVE.search_payload = payload;
      if (LEADACTIVE.agGrid) {
        LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
      }
    }
    if (data.type === "Reminders" || data.type === "all") {
      REMINDERSSTORE.search_payload = payload;
      if (REMINDERSSTORE.agGrid) {
        REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
      }
    }
    // await history.push(`/search/${data.type}?q=${encodeURIComponent(data.search)}`);
    if (data.search_target === "_blank") {
      await window.open(
        `/search/${data.type}?q=${encodeURIComponent(data.search)}`,
        "_blank"
      );
    } else {
      await history.push(
        `/search/${data.type}?q=${encodeURIComponent(data.search)}`
      );
    }
    close();
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      form.submit();
    }
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        searchRef.current?.focus();
      }, 100); // Small delay ensures modal is fully rendered
    }
  }, [visible]);

  useEffect(() => {
    // if (inputValue && radioValue) {
    if (inputValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [inputValue]);

  return (
    <Modal
      destroyOnClose={true}
      centered
      title={`${t("Search.Search_Data")} ${t("Common_Data.By")}`}
      visible={visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      footer={false}
      // footer={[
      //     <Button
      //       key='1'
      //       form='SearchForm'
      //       htmlType='submit'
      //       type='primary'
      //       shape='round'
      //       danger
      //       disabled={isDisabled}
      //     >
      //       {t('Search.Search_Data')}
      //     </Button>,
      // ]}
    >
      <Form
        form={form}
        id="SearchForm"
        className="innerForm topSearchForm"
        layout="vertical"
        onKeyUp={handleKeyUp}
        onFinish={handleSubmit}
      >
        <div>
          <img
            src={AUTH.GetThemedImage("Search_icon")}
            alt=""
            className="Search_Icon"
          />
          <Form.Item
            name="search"
            rules={[
              {
                required: true,
                message: `${t("TopNotificationModule.Not_Blank")}!`,
              },
            ]}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          >
            <AutoComplete autoFocus={true} ref={searchRef}/>
          </Form.Item>
        </div>
        <Form.Item
          name="type"
          // rules={[{ required: true, message: `${t('TopNotificationModule.Search_Type')}!` }]}
          initialValue="all"
          // onChange={(e) => {
          //   setRadioValue(e.target.value)
          // }}
        >
          <Radio.Group options={plainOptions} className="sectorSelector" />
        </Form.Item>

        <div
          className="ant-modal-footer pt-15"
          style={{ margin: "0 -24px -15px" }}
        >
          <Space size={10} align="center">
            <Form.Item name={"search_target"} initialValue="_self" noStyle>
              <Radio.Group value={""}>
                <Radio value="_blank">New Tab</Radio>
                <Radio value="_self">This Tab</Radio>
              </Radio.Group>
            </Form.Item>
            <Button
              key="1"
              form="SearchForm"
              htmlType="submit"
              type="primary"
              shape="round"
              danger
              disabled={isDisabled}
            >
              {t("Search.Search_Data")}
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
});
export default SearchModal;

const getIanaTimeZone = (countryName) => {
  const countryTimeZones = {
    Afghanistan: "Asia/Kabul",
    Albania: "Europe/Tirane",
    Algeria: "Africa/Algiers",
    Andorra: "Europe/Andorra",
    Angola: "Africa/Luanda",
    "Antigua and Barbuda": "America/Antigua",
    Argentina: "America/Argentina/Buenos_Aires",
    Armenia: "Asia/Yerevan",
    Australia: "Australia/Sydney",
    Austria: "Europe/Vienna",
    Azerbaijan: "Asia/Baku",
    Bahamas: "America/Nassau",
    Bahrain: "Asia/Bahrain",
    Bangladesh: "Asia/Dhaka",
    Barbados: "America/Barbados",
    Belarus: "Europe/Minsk",
    Belgium: "Europe/Brussels",
    Belize: "America/Belize",
    Benin: "Africa/Porto-Novo",
    Bhutan: "Asia/Thimphu",
    Bolivia: "America/La_Paz",
    "Bosnia and Herzegovina": "Europe/Sarajevo",
    Botswana: "Africa/Gaborone",
    Brazil: "America/Sao_Paulo",
    Brunei: "Asia/Brunei",
    Bulgaria: "Europe/Sofia",
    "Burkina Faso": "Africa/Ouagadougou",
    Burundi: "Africa/Bujumbura",
    "Cabo Verde": "Atlantic/Cape_Verde",
    Cambodia: "Asia/Phnom_Penh",
    Cameroon: "Africa/Douala",
    Canada: "America/Toronto",
    "Central African Republic": "Africa/Bangui",
    Chad: "Africa/Ndjamena",
    Chile: "America/Santiago",
    China: "Asia/Shanghai",
    Colombia: "America/Bogota",
    Comoros: "Indian/Comoro",
    "Congo (Congo-Brazzaville)": "Africa/Brazzaville",
    "Congo (Congo-Kinshasa)": "Africa/Kinshasa",
    "Costa Rica": "America/Costa_Rica",
    Croatia: "Europe/Zagreb",
    Cuba: "America/Havana",
    Cyprus: "Asia/Nicosia",
    "Czech Republic": "Europe/Prague",
    Denmark: "Europe/Copenhagen",
    Djibouti: "Africa/Djibouti",
    Dominica: "America/Dominica",
    "Dominican Republic": "America/Santo_Domingo",
    Ecuador: "America/Guayaquil",
    Egypt: "Africa/Cairo",
    "El Salvador": "America/El_Salvador",
    "Equatorial Guinea": "Africa/Malabo",
    Eritrea: "Africa/Asmara",
    Estonia: "Europe/Tallinn",
    "Eswatini (Swaziland)": "Africa/Mbabane",
    Ethiopia: "Africa/Addis_Ababa",
    Fiji: "Pacific/Fiji",
    Finland: "Europe/Helsinki",
    France: "Europe/Paris",
    Gabon: "Africa/Libreville",
    Gambia: "Africa/Banjul",
    Georgia: "Asia/Tbilisi",
    Germany: "Europe/Berlin",
    Ghana: "Africa/Accra",
    Greece: "Europe/Athens",
    Greenland: "America/Nuuk",
    Guatemala: "America/Guatemala",
    Guinea: "Africa/Conakry",
    Honduras: "America/Tegucigalpa",
    Hungary: "Europe/Budapest",
    Iceland: "Atlantic/Reykjavik",
    India: "Asia/Kolkata",
    Indonesia: "Asia/Jakarta",
    Iran: "Asia/Tehran",
    Iraq: "Asia/Baghdad",
    Ireland: "Europe/Dublin",
    Israel: "Asia/Jerusalem",
    Italy: "Europe/Rome",
    Jamaica: "America/Jamaica",
    Japan: "Asia/Tokyo",
    Jordan: "Asia/Amman",
    Kazakhstan: "Asia/Almaty",
    Kenya: "Africa/Nairobi",
    Kuwait: "Asia/Kuwait",
    Kyrgyzstan: "Asia/Bishkek",
    Laos: "Asia/Vientiane",
    Latvia: "Europe/Riga",
    Lebanon: "Asia/Beirut",
    Lesotho: "Africa/Maseru",
    Liberia: "Africa/Monrovia",
    Libya: "Africa/Tripoli",
    Liechtenstein: "Europe/Vaduz",
    Lithuania: "Europe/Vilnius",
    Luxembourg: "Europe/Luxembourg",
    Madagascar: "Indian/Antananarivo",
    Malawi: "Africa/Blantyre",
    Malaysia: "Asia/Kuala_Lumpur",
    Malta: "Europe/Malta",
    Mauritius: "Indian/Mauritius",
    Mexico: "America/Mexico_City",
    Monaco: "Europe/Monaco",
    Mongolia: "Asia/Ulaanbaatar",
    Morocco: "Africa/Casablanca",
    Mozambique: "Africa/Maputo",
    "Myanmar (Burma)": "Asia/Yangon",
    Namibia: "Africa/Windhoek",
    Nepal: "Asia/Kathmandu",
    Netherlands: "Europe/Amsterdam",
    "New Zealand": "Pacific/Auckland",
    Nicaragua: "America/Managua",
    Niger: "Africa/Niamey",
    Nigeria: "Africa/Lagos",
    "North Korea": "Asia/Pyongyang",
    Norway: "Europe/Oslo",
    Oman: "Asia/Muscat",
    Pakistan: "Asia/Karachi",
    Palestine: "Asia/Gaza",
    Panama: "America/Panama",
    "Papua New Guinea": "Pacific/Port_Moresby",
    Paraguay: "America/Asuncion",
    Peru: "America/Lima",
    Philippines: "Asia/Manila",
    Poland: "Europe/Warsaw",
    Portugal: "Europe/Lisbon",
    Qatar: "Asia/Qatar",
    Romania: "Europe/Bucharest",
    Russia: "Europe/Moscow",
    Rwanda: "Africa/Kigali",
    "Saudi Arabia": "Asia/Riyadh",
    Senegal: "Africa/Dakar",
    Serbia: "Europe/Belgrade",
    Singapore: "Asia/Singapore",
    Slovakia: "Europe/Bratislava",
    Slovenia: "Europe/Ljubljana",
    "South Africa": "Africa/Johannesburg",
    "South Korea": "Asia/Seoul",
    Spain: "Europe/Madrid",
    "Sri Lanka": "Asia/Colombo",
    Sweden: "Europe/Stockholm",
    Switzerland: "Europe/Zurich",
    Syria: "Asia/Damascus",
    Taiwan: "Asia/Taipei",
    Tanzania: "Africa/Dar_es_Salaam",
    Thailand: "Asia/Bangkok",
    Turkey: "Europe/Istanbul",
    Ukraine: "Europe/Kyiv",
    "United Arab Emirates": "Asia/Dubai",
    "United Kingdom": "Europe/London",
    "United States": "America/New_York",
    Uruguay: "America/Montevideo",
    Uzbekistan: "Asia/Tashkent",
    "Vatican City": "Europe/Vatican",
    Venezuela: "America/Caracas",
    Vietnam: "Asia/Ho_Chi_Minh",
    Zimbabwe: "Africa/Harare",
  };

  return countryTimeZones[countryName] || null;
};

export default getIanaTimeZone;